import React from "react";
import { Link } from "gatsby"
import { indmediaCoverage,rusediaCoverage, cscCoverage } from "../data/events"

export default function mediaCoverage() {

    return(
        <>
          <div className="mt-0 pt-0 mb-5 px-5 container-custom">

          <h1 className="heading my-5"> Media Coverage </h1>

 <h2 className="heading my-4">India ESG Network Launch </h2>
<div className="row">
{indmediaCoverage.map((data, index) => (
<div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3">
<div className="card h-100 custom-card border common-card">
<div className="card-body">
<h4 className="common-second-heading mt-1">{data.heading}</h4>
<p className="pt-2">{data.para}</p>
<p className="pt-2">
  <a href={data.url} style={{ textDecoration: 'underline' }}>
    Read more
  </a>
</p>
</div>
</div>
</div>
))}
</div>
<h2 className="heading my-4">Indo - Russia Round Table </h2>
<div className="row">
{rusediaCoverage.map((data, index) => (
<div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3">
<div className="card h-100 custom-card border common-card">
<div className="card-body">
<h4 className="common-second-heading mt-1">{data.heading}</h4>
<p className="pt-2">{data.para}</p>
<p className="pt-2">
  <a href={data.url} style={{ textDecoration: 'underline' }}>
    Read more
  </a>
</p>
</div>
</div>
</div>
))}
</div>


<h2 className="heading my-4">CSC-IESGN SDG Conclave </h2>
<div className="row">
{cscCoverage.map((data, index) => (
<div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3">
<div className="card h-100 custom-card border common-card">
<div className="card-body">
<h4 className="common-second-heading mt-1">{data.heading}</h4>
<p className="pt-2">{data.para}</p>
<p className="pt-2">
  <a href={data.url} style={{ textDecoration: 'underline' }}>
    Read more
  </a>
</p>
</div>
</div>
</div>
))}
</div>

          </div>
        
        </>
    )
}